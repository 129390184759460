<template>
  <div class="main">
    <div class="instruments">
      <div class="fg">
        <div>gridX</div>
        <input v-model="gridX" type="range" min="1" max="30"/>
        <span>{{gridX}}</span>
        <span
            class="cp"
            @click="gridLockToggle"
            v-html="`${!gridLock?'🔓':'🔒'}`"
        />
      </div>
      <div class="fg">
        <div>gridY</div>
        <input v-model="gridY" type="range" min="1" max="30"/>
        <span>{{gridY}}</span>
        <span
            class="cp"
            @click="gridLockToggle"
            v-html="`${!gridLock?'🔓':'🔒'}`"
        />
      </div>
      <div class="fg">
        <div>outBorder</div>
        <input v-model="outBorder" type="range" min="0" max="500" step="1"/>
      <span>{{outBorder}}</span>
      </div>
      <div class="fg">
        <div>slideX</div>
        <input v-model="slideX" type="range" min="-10" max="10" step="0.01"/>
      <span>{{slideX}}</span>
      </div>
      <div class="fg">
        <div>slideY</div>
        <input v-model="slideY" type="range" min="-10" max="10" step="0.01"/>
      <span>{{slideY}}</span>
      </div>
      <div class="fg">
        <div>shiftX</div>
        <input v-model="shift[0]" type="range" min="-100" max="100" step="0.1"/>
      <span>{{shift[0]}}</span>
      </div>
      <div class="fg">
        <div>shiftY</div>
        <input v-model="shift[1]" type="range" min="-100" max="100" step="0.1"/>
      <span>{{shift[1]}}</span>
      </div>
      <div class="fg">
        <div>squeezeX</div>
        <input v-model="scale[0]" type="range" min="0.1" max="3" step="0.01"/>
      <span>{{scale[0]}}</span>
      </div>
      <div class="fg">
        <div>squeezeY</div>
        <input v-model="scale[1]" type="range" min="0.1" max="3" step="0.01"/>
      <span>{{scale[1]}}</span>
      </div>
      <div class="fg">
        <div>rotation</div>
        <input v-model="rotation" type="range" min="-180" max="180" step="0.01"/>
        <span>{{rotation}}</span>

        <span
            class="cp"
            @click="timerToggle"
            v-html="`${!timerRun?'🛑':'🔄'}`"
        />
      </div>
      <div class="fg">
        <div>resize</div>
        <input v-model="resize" type="range" min="0" max="2" step="0.01"/>
      <span>{{resize}}</span>
      </div>
      <div class="fg">
        <div>Rotation logic</div>
        <input v-model="rotationLogic" type="range" min="1" :max="`${logics[0]}`" step="1"/>
      <span>{{rotationLogic}}</span>
      </div>
      <div class="fg">
        <div>Center logic</div>
        <input v-model="centerLogic" type="range" min="1" :max="`${logics[1]}`" step="1"/>
      <span>{{centerLogic}}</span>
      </div>
      <div class="fg">
        <div>Resize logic</div>
        <input v-model="resizeLogic" type="range" min="1" :max="`${logics[2]}`" step="1"/>
      <span>{{resizeLogic}}</span>
      </div>
      <div class="fw jcsa w100 instrument_buttons">
        <button
            @click="downloadImg"
        >
          Download
        </button>
        <button
            @click="upload"
        >Upload
        </button>
        <input
            style="display:none;"
            id="module_upload"
            ref="fileInput"
            class="c-section-add__input"
            type="file"
            accept=".svg"
            @change="change"
        >
        <button
            @click="preset"
        >Preset
        </button>
        <button
            @click="random"
        >Random
        </button>
      </div>
    </div>
    <div class="svg_field">
      <div class="c-img">
        <svg
            id="theCanvas"
            ref="svgCanvas"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            :viewBox="`${-outerBorder[3]} ${-outerBorder[0]} ${ Number(cnvSize[0])+Number(outerBorder[1])+Number(outerBorder[3]) } ${ Number(cnvSize[1])+Number(outerBorder[0])+Number(outerBorder[2])}`"
            :width="`${  Number(cnvSize[0])+ Number(outerBorder[1])+Number(outerBorder[3])}`"
            :height="`${ Number(cnvSize[1])+Number(outerBorder[0])+Number(outerBorder[2])}`"
        >
          <defs>
            <clipPath
                v-for="(component, index) in gridItems"
                :key="`path${index}`"
                :id="`tile_${ index }`"
                v-html='`<rect x="${ component.x }" y="${ component.y }" width="${ component.hx }" height="${ component.hy }" />`'
            />
            <g
                id="input_1"
                v-html="`${origin}`"
                :transform="`scale(${scale[0]} ${scale[1]})`"
            />
          </defs>
          <use
              xlink:href="#input_1"
              v-for="(component, index) in gridItems"
              :key="`component${index}`"
              :clip-path="`url(#tile_${ index })`"
              :width="`${rnd(component.hx)}`"
              :height="`${rnd(component.hy)}`"
              :transform="`translate(${rnd(component.sx)} ${rnd(component.sy)}) ${component.transform?component.transform:''}`"
          />
        </svg>
      </div>

    </div>
  </div>
</template>

<script>
import {parse} from 'postsvg'

export default {
  name: 'TilerApp',
  props: {
    msg: String
  },
  data() {
    return {
      settings: {},
      gridLock: false,
      roundValue: 5,
      canvasComponents: {},
      gridX: 10,
      gridY: 10,
      objectSize: [96, 96],
      slideX: 0,
      slideY: 0,
      shift:[0,0],
      scale:[1,1],
      rotation: 0,
      resize: 1,
      rotationLogic: 1,
      centerLogic: 1,
      resizeLogic: 1,
      logics: [7, 5, 2],
      outBorder: 10,
      timerRun: false,
      timer: null,
      origin: '<linearGradient id="myGradient" gradientTransform="rotate(45)">\n' +
          '          <stop offset="5%"  stop-color="gold" />\n' +
          '          <stop offset="95%" stop-color="red" />\n' +
          '        </linearGradient>' +
          '<g\n' +
          '          id="input_1"\n' +
          '          pointer-events="all"\n' +
          '          transform=""\n' +
          '          style="pointer-events: visibleFill;"\n' +
          '        >\n' +
          '        <rect fill=\'url("#myGradient")\' height=\'96\' width=\'96\'/>\n' +
          '        <path d=\'M96,24H48.54741c-13.25915,0-24.6033,10.84468-24.54719,24.10371a23.90091,23.90091,0,0,0,6.32016,16.12252A4.00675,4.00675,0,0,1,27.441,71H0v1H47.45259c13.25915,0,24.6033-10.84468,24.54719-24.10371a23.90091,23.90091,0,0,0-6.32016-16.12252A4.00675,4.00675,0,0,1,68.559,25H96Z\' fill=\'#fff\'/>\n' +
          '        <circle cx=\'48\' cy=\'48\' r=\'23\' fill=\'#231f20\'/>\n' +
          '        <circle cx=\'48\' cy=\'31\' r=\'2\' fill=\'#fff\'/>\n' +
          '      </g>'
    }
  },
  watch: {
    gridX(value) {
      if (this.gridLock) {
        this.gridY = value
      }
    },
    gridY(value) {
      if (this.gridLock) {
        this.gridX = value
      }
    },
    gridLock() {
      if (this.gridLock) {
        this.gridY = this.gridX
      }
    },
  },
  computed: {
    shiftX() {
      return this.rnd(this.shift[0]*this.objectSize[0]/1000)
    },
    shiftY() {
      return this.rnd(this.shift[1]*this.objectSize[1]/1000)
    },
    svgCanvas() {
      return this.$refs.svgCanvas
    },
    cnvSize() {
      return [
        this.rnd(this.slideX > 0 ? Number(this.objectSize[0]) + Number(this.slideX * (this.gridX)) : this.objectSize[0]),
        this.rnd(this.slideY > 0 ? Number(this.objectSize[1]) + Number(this.slideY * (this.gridY)) : this.objectSize[1])
      ]
    },
    size() {
      return [this.rnd(this.objectSize[0] / this.gridX), this.rnd(this.objectSize[0] / this.gridY)]
    },
    outerBorder() {
      return [this.outBorder, this.outBorder, this.outBorder, this.outBorder]
    },
    gridItems() {
      let aggr = []
      const sx = this.size[0]
      const sy = this.size[1]
      for (let x = 0; x < this.gridX; x++) {
        for (let y = 0; y < this.gridY; y++) {
          let shiftX = sx * x
          let shiftY = sy * y
          let lSlideX = this.slideX * x
          let lSlideY = this.slideY * y
          switch (Number(this.centerLogic)) {
            case 2:
              lSlideX = this.slideX;
              lSlideY = this.slideY;
              break
            case 3:
              lSlideX = this.slideX * (this.gridX - x);
              lSlideY = this.slideY * (this.gridY - y);
              break
            case 4:
              lSlideX = this.slideX * (x + 1);
              lSlideY = this.slideY * (y + 1);
              break
            case 5: {
              if ((x + y) % 2 !== 0) {
                lSlideX = this.slideX;
                lSlideY = this.slideY;
              }
            }
              break
          }
          const centerX = Number(shiftX + (sx * 0.5)) + Number(lSlideX)
          const centerY = Number(shiftY + (sy * 0.5)) + Number(lSlideY)
          let rotation = this.rotation
          switch (Number(this.rotationLogic)) {
            case 2:
              rotation = Number(rotation) + Number(rotation / (this.gridX * this.gridY) * x * y);
              break
            case 3:
              rotation = Number(rotation) + Number(rotation / (this.gridX * this.gridY) * x);
              break
            case 4:
              rotation = Number(rotation) + Number(rotation / (this.gridX * this.gridY) * y);
              break
            case 5:
              rotation = Number(rotation) + Number(rotation / (this.gridX * this.gridY) * x * 2);
              break
            case 6:
              rotation = Number(rotation) + Number(rotation / (this.gridX * this.gridY) * y * 2);
              break
            case 7: {
              const max = Math.sqrt((this.gridX / 2) ** 2 + (this.gridY / 2) ** 2)
              const ny = (Math.pow(this.gridY / 2 - y, 2))
              const nx = (Math.pow(this.gridX / 2 - x, 2))
              let power = (Math.sqrt((ny + nx)) / max) ** 2
              rotation = Number(rotation) + Number(rotation / (this.gridX * this.gridY) * power)
            }
              break
            default:
              break
          }
          let resize = this.resize
          switch (Number(this.resizeLogic)) {
            case 2:
              resize = this.resize * (1 + (x * y) / 100);
              break
          }
          aggr.push({
            hx: this.rnd(this.size[0]),
            hy: this.rnd(this.size[1]),
            xv: this.rnd(x),
            yv: this.rnd(y),
            sx: this.rnd(x*this.slideX - Number(this.shiftX*x)),
            sy: this.rnd(y*this.slideY - Number(this.shiftY*y)),
            x: this.rnd(shiftX + Number(this.shiftX*x)),
            y: this.rnd(shiftY + Number(this.shiftY*y)),
            transform: 'scale(' + this.rnd(resize) + ') rotate(' + this.rnd(rotation) + ' ' + this.rnd(centerX) + ' ' + this.rnd(centerY) + ' )'
          })
        }
      }
      return aggr
    },
  },
  async mounted() {
    window.addEventListener('keydown', this.keyListener, false)
  },
  methods: {
    keyListener(e) {
      switch (e.code) {
        case "Space":
          e.preventDefault()
          this.timerToggle()
          break
        case "KeyD":
          e.preventDefault()
          this.downloadImg()
          break
        case "KeyR":
          e.preventDefault()
          this.random()
          break
      }
    },
    getTheToday() {
      let today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')
      const hours = String(today.getHours()).padStart(2, '0')
      const minutes = String(today.getMinutes()).padStart(2, '0')
      const seconds = String(today.getSeconds()).padStart(2, '0')
      today = year + '' + month + '' + day + '_' + hours + '' + minutes + '' + seconds
      return today
    },
    downloadImg() {
      const today = this.getTheToday()
      if (typeof this.svgCanvas === 'object' && this.svgCanvas !== null
      ) {
        const stringSvg = new XMLSerializer().serializeToString(this.svgCanvas)
        const repl = 'xmlns:xlink="http://www.w3.org/1999/xlink"'
        const clearedSvg = this.replaceAllExceptFirst(stringSvg,repl,'')
        const element = document.createElement('a')
        element.download = `${today}.svg`
        element.href = 'data:application/octet-stream;base64,' + window.btoa(clearedSvg)
        element.click()
        element.remove()
      }
    },
    uploadFile(file) {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onerror = () => {
        this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1)
      }
      reader.onload = () => {
        this.origin = reader.result.replace(/<svg[^>]*/gm, '<g ').replace('</svg>', '</g>')
        Promise.resolve(parse(reader.result))
            .then(svgTree => {
              let width = 96
              let height = 96

              if (svgTree.root.attrs.viewBox) {
                width = svgTree.root.attrs.viewBox.split(' ')[2]
                height = svgTree.root.attrs.viewBox.split(' ')[3]
              } else if (svgTree.root.attrs.width) {
                width = svgTree.root.attrs.width
                height = svgTree.root.attrs.height
              }
              this.objectSize = [width, height]
            })
      }
    },
    change(e) {
      this.uploadingFiles = Array.from(e.target.files)

      this.uploadingFiles.forEach(file => {
        this.uploadFile(file)
      })
    },
    upload() {
      document.getElementById('module_upload').click()
    },
    preset() {
      this.gridX = 10
      this.gridY = 10
      this.slideX = 0
      this.slideY = 0
      this.shift = [0,0]
      this.rotation = 0
      this.rotationLogic = 1
      this.centerLogic = 1
      this.resizeLogic = 1
    },
    random() {
      this.gridX = 5 + Math.floor(Math.random() * 15)
      this.gridY = 5 + Math.floor(Math.random() * 15)
      this.slideX = 2 - Math.floor(Math.random() * 4)
      this.slideY = 2 - Math.floor(Math.random() * 4)
      this.shift = [5 - Math.floor(Math.random() * 10),5 - Math.floor(Math.random() * 10)]
      this.rotation = 180 - Math.floor(Math.random() * 360)
      this.rotationLogic = 1 + Math.floor(Math.random() * (this.logics[0] - 1))
      this.centerLogic = 1 + Math.floor(Math.random() * (this.logics[1] - 1))
      this.resizeLogic = 1 + Math.floor(Math.random() * (this.logics[2] - 1))
    },
    gridLockToggle() {
      this.gridLock = !this.gridLock
    },
    startAnimation() {
      this.timer = setInterval(() => {
        if (this.rotation > -1080) {
          this.rotation--
        } else {
          this.rotation = 1080
        }
      }, 10)
      this.timerRun = true
    },
    stopAnimation() {
      clearInterval(this.timer)
      this.timer = null
      this.timerRun = false
    },
    timerToggle() {
      console.log(this.timerRun)
      if (this.timerRun) {
        this.stopAnimation()
      } else {
        this.startAnimation()
      }
    },
    decimalAdjust(type, value, exp) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
},
    rnd(value){
      const ext = Math.pow(10,this.roundValue)
      return Math.round(value * ext) / ext;
    },
    replaceAllExceptFirst(str, search, replace) {
      return str
          .split(search)
          .reduce((prev, curr, i) => prev + (i == 1 ? search : replace) + curr);
    }  },
  beforeUnmount() {
    this.stopAnimation()
    window.removeEventListener('keydown', this.keyListener, false)
  }

}
</script>

<style>
body {
  background-color: #eee;
  margin: 0;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.main {
  display: grid;
  grid-template-columns: 500px 1fr;
  width: 100vw;
  height: 100vh;
}

.instruments, .svg_field {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.svg_field {
  padding: 20px;
  background-color: #ddd;
}

.instruments {
  background-color: #111;
  height: 100%;
}

input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 10px;
  cursor: pointer;
  background-size: cover;
}

input[type="range"] {
  height: 4px;
  background: #fff;
  margin: 5px 20px;

}

#theCanvas {
  height: Calc(100vh - 47px);
  width: Calc(100vw - 550px);
  border: solid 1px #666;
  box-sizing: border-box;
}

.fw {
  display: flex;
  flex-wrap: wrap;
}

.w100 {
  width: 100%;
}

.p20 {
  padding: 20px;
}

.jcsa {
  display: flex;
  justify-content: space-around;
}

.instrument_buttons button {
  background-color: transparent;
  color: #eee;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
  border: solid 1px #eee;
  transition: all 0.5s;
  cursor: pointer;
}

.instrument_buttons button:hover {
  background-color: #666;
}

.cp {
  cursor: pointer;
}

.instruments .fg{
  display:grid;
  width: 100%;
  grid-template-columns: 120px 1fr 30px 20px;
  padding: 0 20px;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 4px;
  background: #969696;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  width: 90%;
  margin: 0;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 25px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 0;
}

input[type="range"]::-moz-range-thumb {
  width: 10px;
  height: 25px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 0;

}

</style>
